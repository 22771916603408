import { createSlice } from '@reduxjs/toolkit';
import { emptyApi as api } from '@edge/frontend.open-api';

type ClientIpState = {
  ip: string;
};
const clientIpSlice = createSlice({
  name: 'clientIp',
  initialState: { ip: null } as ClientIpState,
  reducers: {
    setClientIp(state, action) {
      state.ip = action.payload;
    },
    resetClientIp(state) {
      state.ip = null;
    },
  },
});
export const { setClientIp, resetClientIp } = clientIpSlice.actions;
export default clientIpSlice.reducer;
