import { Action, AnyAction, ThunkAction, ThunkDispatch, configureStore } from '@reduxjs/toolkit';
import { createWrapper } from 'next-redux-wrapper';

import { emptyApi as edgeApi } from '@edge/frontend.open-api';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import clientIp from './clientIp';

export const makeStore = () =>
  configureStore({
    reducer: {
      [edgeApi.reducerPath]: edgeApi.reducer,
      ['clientIp']: clientIp,
    },
    devTools: process.env.NODE_ENV == 'development',
    middleware: gDM => gDM().concat(edgeApi.middleware),
  });

type AppStore = ReturnType<typeof makeStore>;
type TypedDispatch<T> = ThunkDispatch<T, any, AnyAction>;
export type AppState = ReturnType<AppStore['getState']>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, AppState, unknown, Action>;
export const useAppDispatch = () => useDispatch<TypedDispatch<AppState>>();
export const useTypedSelector: TypedUseSelectorHook<AppState> = useSelector;
export const wrapper = createWrapper<AppStore>(makeStore, { debug: false });
